export default {
	passGen: {
		mainTitle: 'Password Generator',
		inputLabel: 'Select the number of characters in the password',
		passwordWillUse: 'The password will use',
		lettersLatin: 'letters of the Latin alphabet',
		specialCharacters: 'special characters',
		numbers: 'numbers',
		inputTooltip: 'The number must be two-digit',
		inputPlaceholder: 'Enter a two-digit number',
		StartPasswordGen: 'Starting password generation',
		btnReset: 'Reset',
		btnStart: 'Start',
	},
	guessNum: {
		mainTitle: "The game 'Guess the number'",
		inputLabel: 'Guess the number from 0 to 100',
		numberCannotBeLess: 'The number cannot be less than 0 and more than 100',
		placeholder: 'Enter a number',
		answer: 'Answer',
		answerTooltip: 'Check the correctness of the answer',
		repeat: 'Repeat',
		clear: 'Clear',
		clearTheForm: 'Clear the form / New round',
		correctAnswer: 'Find out the correct answer',
		exit: 'Exit',
		attempts: 'You have attempts',
		from: 'from',
		negative: "The value cannot be negative!",
		moreHundred: "The value cannot be more than 100!",
		exitСompleted: "The exit is completed!",
		correctAnswerWas: 'The correct answer was a number',
		сongratulations: "Congratulations!!! You guessed it!",
		unfortunately: 'Unfortunately, there are no more attempts left. The correct answer was a number',
		yourNumber: 'Your number',
		tooSmall: 'is too small',
		tooBig: 'is too big',
	}
}