export default {
	passGen: {
		mainTitle: 'Generátor Hesiel',
		inputLabel: 'Vyberte počet znakov v hesle',
		passwordWillUse: 'Heslo bude používať',
		lettersLatin: 'písmená latinskej abecedy',
		specialCharacters: 'špeciálne znaky',
		numbers: 'čísla',
		inputTooltip: 'Číslo musí byť dvojciferné',
		inputPlaceholder: 'Zadajte dvojciferné číslo',
		StartPasswordGen: 'Spustenie generovania hesla',
		btnReset: 'Obnoviť',
		btnStart: 'Začať',
	},
	guessNum: {
		mainTitle: "Hra 'Hádaj číslo'",
		inputLabel: 'Hádajte číslo od 0 do 100',
		numberCannotBeLess: 'Číslo nemôže byť menšie ako 0 a viac ako 100',
		placeholder: 'Zadajte číslo',
		answer: 'Odpoveď',
		answerTooltip: 'Skontrolujte správnosť odpovede',
		repeat: 'Opakovať',
		clear: 'Vymazať',
		clearTheForm: 'Vymazať formulár / Nové kolo',
		correctAnswer: 'Zistite správnu odpoveď',
		exit: 'Ukončiť',
		attempts: 'Počet pokusov',
		from: 'z',
		negative: "Hodnota nemôže byť záporná!",
		moreHundred: "Hodnota nemôže byť vyššia ako 100!",
		exitСompleted: "Výstup je dokončený!",
		correctAnswerWas: 'Správna odpoveď je číslo',
		сongratulations: "Gratulujeme!!! Uhádli ste!",
		unfortunately: 'Bohužiaľ už nezostali žiadne pokusy. Správna odpoveď je číslo',
		yourNumber: 'Vaše číslo',
		tooSmall: 'je príliš malý',
		tooBig: 'je príliš veľký',

	}
}